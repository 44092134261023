var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ClubFind,"mobile-breakpoint":"0","sort-by":"name","search":_vm.filter,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.name,"id":item._id,"label":"Name"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'name', value);
}}})]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.url,"id":item._id,"label":"Homepage"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'url', value);
}}})]}},{key:"item.teams",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.open(item)}}},[_vm._v(" "+_vm._s((item.teams || []).length)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.identifiers",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openidentifiers(item)}}},[_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")])]}}])})],1),_c('clubteams',{attrs:{"teams":_vm.dialog.teams,"id":_vm.dialog.id},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}}),_c('identifiers',{attrs:{"id":_vm.identifiersdialog.id,"identifiers":_vm.identifiersdialog.identifiers,"collection":"Club"},model:{value:(_vm.identifiersdialog.open),callback:function ($$v) {_vm.$set(_vm.identifiersdialog, "open", $$v)},expression:"identifiersdialog.open"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }